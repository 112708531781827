import React, { useEffect, useState, useReducer, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash.throttle'
import Helmet from 'react-helmet'
import classnames from 'classnames'

import Layout from '../components/Layout'
import {
  DEFAULT_EVENT_FILTERS,
  EventFilterContext,
  EventFilterDispatchContext,
} from '../components/Events/Filters'
import {
  initStateArray,
  arrayReducer,
  initStateObject,
  objectReducer,
  OVERWRITE,
} from '../reducers/default'
import { fetchEvent, fetchEvents } from '../api/events'
import LoadingSpinner, { SpinnerSize } from '../components/Shared/LoadingSpinner'
import EventList from '../components/Event/List/EventList'
import EventRow from '../components/Event/List/EventRow'
import SideDrawer from '../components/Shared/SideDrawer'
import EventDetails from '../components/Event/Details/EventDetails'
import { getEventFetchFilters } from '../api/models'
import EventControlPanel from 'components/Events/EventControlPanel'
import { EventStatsProvider } from 'components/Event/Details/EventStatsProvider'
import EventAttributionModelContextProvider from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const pageInit = { number: 1, loading: false }

/**
 * Event search page.
 * It displays table with events based on searched query.
 * @Tags( page, layout, logged-users, search, api )
 * @Inputs( search )
 * @SuggestedTags( dashboard )
 * @Endpoints( /events )
 * @ApiLogic( ../api/events )
 * @Routes( /events )
 */
const Events = () => {
  const { t } = useTranslation()
  const [selectedEvent, eventDispatch] = useReducer(objectReducer, initStateObject)
  const [page, setPage] = useState({ ...pageInit })
  const [events, dispatch] = useReducer(arrayReducer, initStateArray)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const filters = useContext(EventFilterContext)
  const dispatchFiltersAction = useContext(EventFilterDispatchContext)
  const [eventSeries, dispatchEventSeries] = useReducer(objectReducer, initStateObject)
  const hasLookoutAccess = useHasPermission(Permissions.lookout)

  useEffect(() => {
    if (!drawerOpen) {
      if (!selectedEvent.loading) eventDispatch({ type: OVERWRITE, payload: initStateObject })

      return
    }

    const urlParams = new URL(window.location.href)
    const eventId = urlParams.searchParams.get('event-id')
    if (selectedEvent.content.id === eventId) return

    eventId && fetchEvent(eventDispatch, eventId)
  }, [drawerOpen, selectedEvent.loading, selectedEvent.content.id])

  const loadNextPage = useCallback(() => {
    if (page.loading) return

    const pageNumber = page.number + 1
    setPage({ number: pageNumber, loading: true })

    fetchEvents(
      (action) => {
        dispatch(action)
        setPage((page) => ({ ...page, loading: false }))
      },
      getEventFetchFilters(filters, pageNumber),
    )
  }, [page, filters])

  useEffect(() => {
    const handleScroll = () => {
      if (
        events.maxPage > page.number &&
        !page.loading &&
        document.body.scrollHeight - (window.innerHeight + window.scrollY) < 300
      ) {
        loadNextPage()
      }
    }

    const throttledScroll = throttle(handleScroll, 1000)

    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, page])

  const filterEvents = useCallback((eventFilters) => {
    if (!eventFilters) return

    setPage({ ...pageInit })
    fetchEvents(dispatch, getEventFetchFilters(eventFilters))
    dispatchFiltersAction({ type: 'SET_OPEN', payload: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetFilters = (includeAll = true) => {
    if (includeAll) {
      dispatchFiltersAction({ type: 'CLEAR_ALL' })
      filterEvents({ ...DEFAULT_EVENT_FILTERS })
    } else {
      dispatchFiltersAction({ type: 'CLEAR_ALL_BUT_PAST' })
      filterEvents({ ...DEFAULT_EVENT_FILTERS, includePastEvents: filters?.includePastEvents })
    }
  }

  useEffect(() => {
    filterEvents(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout
      loggedIn
      title={t('Events.PageTitle')}
      mobileTopBar={{ centerTitle: t('Events.MobileTitle') }}
    >
      <Helmet bodyAttributes={{ class: 'events-page' }} />
      <EventAttributionModelContextProvider eventId={selectedEvent.content.id}>
        <EventStatsProvider eventId={selectedEvent.content.id}>
          <main>
            <div
              style={{ minHeight: '100vh', paddingBottom: '8em' }}
              className="container-fluid hide-scroll-bar"
            >
              <div className="col-12 pl-0">
                <h1 className="main-title hide-mobile">{t('Events.MainTitle')}</h1>
              </div>
              <div className="mt-4">
                <EventControlPanel
                  filters={filters}
                  onDispatch={dispatchFiltersAction}
                  onSubmit={filterEvents}
                  onReset={(isMobile) => resetFilters(isMobile)}
                  disabled={events.loading}
                />
              </div>
              <div
                className={classnames({
                  'events-page-search-filter_table card mt-3': true,
                  'events-page-search-filter_table--filtering': filters.open,
                })}
                data-testid="events-search-table"
              >
                {drawerOpen &&
                  selectedEvent.content.id &&
                  events.items.every((x) => x.id !== selectedEvent.content.id) && (
                    <EventRow
                      event={selectedEvent.content}
                      key={selectedEvent.content.id}
                      isSelected
                      isDisabled={!hasLookoutAccess}
                      classNames="hover"
                    />
                  )}
                <div
                  data-testid="events-search-table-results"
                  className="events-page-search-filter_results_container"
                >
                  {events.items.length > 0 ? (
                    <EventList
                      events={events.items}
                      rowRender={(event) => (
                        <EventRow
                          event={event}
                          onClick={(eventId) => {
                            eventId && fetchEvent(eventDispatch, eventId)
                          }}
                          key={event.id}
                          shouldScrollOnLoad
                          isSelected={selectedEvent.content.id === event.id}
                          isDisabled={!hasLookoutAccess}
                          classNames="hover"
                        />
                      )}
                    />
                  ) : null}
                </div>
                {!events.loading && !events.items.length > 0 ? (
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    {t('Events.results.empty')}
                  </p>
                ) : null}
              </div>
              {events.loading ? (
                <LoadingSpinner portalBackground={false} size={SpinnerSize.LARGE} />
              ) : null}
              <SideDrawer
                drawerOpen={drawerOpen}
                toggle={setDrawerOpen}
                classes="no-padding overflow-auto"
              >
                <EventDetails
                  event={selectedEvent.content}
                  dispatchEventSeries={dispatchEventSeries}
                  eventSeries={eventSeries.content}
                />
              </SideDrawer>
            </div>
          </main>
        </EventStatsProvider>
      </EventAttributionModelContextProvider>
    </Layout>
  )
}

export default Events

// Events.whyDidYouRender = true
