import React, {
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { debounce, isEqual, orderBy } from 'lodash'
import i18n from 'i18n'
import * as Sentry from '@sentry/react'

import setupProcessApi from 'api/setupProcesses'
import { useImmerReducerAsync } from 'hooks/useReducerAsync'
import {
  buildSetupPayload,
  isCampaignRunning,
  prepareTargetingPayload,
  validateCreative,
  validateIntegrations,
} from 'api/models'
import { initStateObject, objectReducer, UPDATE } from 'reducers/default'
import {
  isLocationTargetingEmpty,
  TARGETING_MODES,
  validateLocationTargeting,
} from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { fileStatus } from 'components/Recommendations/Campaign/Media/media'
import { userReportsApi } from 'api/userReports'
import { TargetingContextProvider } from '../Edit/CampaignForm/Targeting/TargetingContext'
import useHasPermission from 'hooks/useHasPermission'
import { offsetVariationsToHandleRemovedCreativeFields } from './disabledVariations'
import campaignSetupApi from 'api/campaigns'
import { hashObjectValues } from 'utils/helpers'

const CampaignSetupContext = createContext(null)
const CampaignSetupDispatchContext = createContext(null)
export const CurrentStepContext = createContext(null)
export const CurrentStepDispatchContext = createContext(null)
export const AvailableTasteClustersContext = createContext(null)

export function CampaignSetupStepProvider({ children }) {
  const [currentStepState, dispatchCurrentStep] = useReducer(objectReducer, {
    ...initStateObject,
    content: {
      currentStep: 1,
      currentCompletedStep: null,
    },
  })

  const setCurrentStep = useCallback(
    (step, completedStep = null) => {
      const payload = completedStep ? { currentCompletedStep: completedStep } : {}
      dispatchCurrentStep({ type: UPDATE, payload: { ...payload, currentStep: step } })
    },
    [dispatchCurrentStep],
  )

  const goToNextStep = useCallback(() => {
    if (currentStepState.content.currentStep === 5) return

    const { currentStep, currentCompletedStep } = currentStepState.content
    setCurrentStep(currentStep + 1, currentCompletedStep + 1)
  }, [currentStepState, setCurrentStep])

  const goToPrevStep = useCallback(() => {
    if (currentStepState.content.currentStep === 1) return

    const { currentStep, currentCompletedStep } = currentStepState.content
    setCurrentStep(currentStep - 1, currentCompletedStep - 1)
  }, [currentStepState, setCurrentStep])

  return (
    <CurrentStepContext.Provider value={currentStepState.content}>
      <CurrentStepDispatchContext.Provider value={{ setCurrentStep, goToNextStep, goToPrevStep }}>
        {children}
      </CurrentStepDispatchContext.Provider>
    </CurrentStepContext.Provider>
  )
}

CampaignSetupStepProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Provides the context for available taste clusters.
 * For now, it's mainly used to keep track of the open taste clusters.
 *
 * @component
 */
function AvailableTasteClustersContextProvider({ children }) {
  const openTCs = useState([])

  return (
    <AvailableTasteClustersContext.Provider value={{ openTCs }}>
      {children}
    </AvailableTasteClustersContext.Provider>
  )
}

AvailableTasteClustersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function CampaignSetupProvider({ eventId, campaign, campaigns, children }) {
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const initialState = useMemo(() => {
    if (!eventId) return null

    if (!campaign || !campaign.latest_campaign_setup) {
      return {
        ..._initialState,
        eid: eventId,
      }
    }

    const creative = campaign.latest_campaign_setup.creative

    return {
      ..._initialState,
      eid: eventId,
      isPublished: true,
      setup: {
        ...campaign.latest_campaign_setup,
        creatives: [
          {
            ...creative,
            tc: campaign.tc,
            tc_run_id: campaign.tc_run_id,
            audience_id: creative.audience_id,
          },
        ],
      },
    }
  }, [eventId, campaign])

  const [state, dispatch] = useImmerReducerAsync(reducer, initialState, asyncActionHandlers)
  const { eid, setup, loading, hasPendingMedia, isPublished } = state
  const shouldAutosync = useMemo(() => !campaign?.latest_campaign_setup, [campaign])
  const disabledVariationsHashRef = useRef(isPublished ? getDisabledVariationHashes(setup) : {})

  useEffect(() => {
    if (!setup) {
      disabledVariationsHashRef.current = {}
      return
    }

    const defaultConversionId = getDefaultConversionId(campaigns, setup)
    dispatch({ type: actions.setConversion, payload: defaultConversionId }, [defaultConversionId])
  }, [campaigns, setup, dispatch])

  const fetchSetup = useCallback(() => {
    const action = { type: actions.fetchAsync, payload: { eid } }
    fetchSetupAsync({ dispatch })(action).then(({ success, setup }) => {
      if (success && setup) {
        disabledVariationsHashRef.current = getDisabledVariationHashes(setup)
      }
    })
  }, [dispatch, eid])

  const debouncedSync = useRef(
    debounce((setup, callback = () => {}) => {
      if (!shouldAutosync) {
        return
      }

      const action = { type: actions.syncAsync, payload: { eid, setup } }
      syncSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    }, 1000),
  )

  const sync = useCallback(
    (setup, callback = () => {}) => {
      const action = { type: actions.syncAsync, payload: { setup } }
      syncSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const startSetup = useCallback(
    (config, callback = () => {}) => {
      const action = {
        type: actions.createAsync,
        payload: { eid: state.eid, config },
      }
      createSetupAsync({ dispatch })(action).then(callback)
    },
    [dispatch, state],
  )

  const reconfigureSetup = useCallback(
    (config, callback = () => {}) => {
      const action = {
        type: actions.reconfigureAsync,
        payload: { eid: state.eid, config },
      }
      reconfigureSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const cancelSetup = useCallback(
    (callback = () => {}) => {
      const action = {
        type: actions.deleteAsync,
        payload: { eid: state.eid },
      }
      deleteSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const setHasPendingMedia = useCallback(
    (hasPendingMedia) => {
      dispatch({ type: actions.setHasPendingMedia, payload: hasPendingMedia })
    },
    [dispatch],
  )

  const overwriteTCCreatives = useCallback(
    ({ tc, tc_run_id, creatives, creativeAction }) => {
      const { removedFields } = creativeAction
      if (removedFields) {
        creatives.excluded_variations = offsetVariationsToHandleRemovedCreativeFields(
          removedFields,
          setup.creatives,
          tc,
        )
      }

      dispatch({
        type: actions.setTCCreatives,
        payload: { tc, tc_run_id, ...creatives },
      })

      const newCreatives = calculateSetupCreativesFromUpdatedTCCreatives(
        setup.creatives,
        tc,
        tc_run_id,
        creatives,
      )

      if (shouldAutosync) {
        debouncedSync.current.cancel()
        debouncedSync.current({ ...setup, creatives: newCreatives })
      }
    },
    [dispatch, setup, shouldAutosync],
  )

  const updateDisabledAdVariations = useCallback(
    (disabledVariations, callback = () => {}, autoSync = true) => {
      const creatives = setup.creatives.map((creative) => ({
        ...creative,
        excluded_variations: disabledVariations[creative.tc],
      }))

      dispatch({
        type: actions.setCreatives,
        payload: creatives,
      })

      if (autoSync) {
        sync({ ...setup, creatives }, ({ success, data }) => {
          if (success) {
            disabledVariationsHashRef.current = getDisabledVariationHashes({ ...setup, creatives })
          }
          callback({ success, data })
        })
      }
    },
    [dispatch, setup, sync],
  )

  const overwriteTCTargeting = useCallback(
    (tcTargeting, callback = () => {}) => {
      const action = {
        type: actions.updateTCTargetingAsync,
        payload: { ...tcTargeting, shouldSync: !isPublished },
      }
      
      updateTCTargetingAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state, isPublished],
  )

  const checkDisabledVariationsIntegrity = useCallback((currentDisabledVariations = null) => {
    const creatives = setup.creatives
    const integrityHashes = disabledVariationsHashRef.current

    return _checkDisabledVariationsIntegrity(creatives, integrityHashes, currentDisabledVariations)
  }, [setup])

  useEffect(() => {
    if (!setup) {
      return
    }

    const disabledVariations = setup.creatives.reduce((acc, c) => {
      acc[c.tc] = c.excluded_variations || []
      return acc
    }, {})

    if (!isEqual(disabledVariations, state.disabledVariations)) {
      dispatch({ type: actions.setSetupDisabledVariations, payload: disabledVariations })
    }
  }, [setup, dispatch, state.disabledVariations])

  const publishSetup = useCallback(
    (callback = () => {}) => {
      const action = { type: actions.publicAsync }
      publishSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const editPublishedSetup = useCallback(
    (callback = () => {}, disabledVariations = null) => {
      const action = {
        type: actions.editAsync,
        payload: { tc: campaign.tc, tc_run_id: campaign.tc_run_id, disabledVariations },
      }
      editPublishedSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state, campaign],
  )

  const isTargetingValid = useMemo(() => {
    if (!Array.isArray(setup?.targeting)) return false

    const targeting = setup.targeting
    return targeting.every(
      (x) =>
        validateLocationTargeting(
          targeting,
          x.tc,
          x.tc_run_id,
          hasCustomAudience(x.tc, setup.creatives),
        ).valid,
    )
  }, [setup])

  const isIntegrationsValid = useMemo(
    () => setup && validateIntegrations(setup.integration_details),
    [setup],
  )

  const isCreativesValid = useMemo(
    () => setup && setup.creatives.every((x) => validateCreative(x, { setupOnly: false }).valid),
    [setup],
  )

  const isStepValid = useCallback(
    (step) => {
      switch (step) {
        case 1:
          return true
        case 2:
          return isIntegrationsValid && !loading
        case 3:
          return (
            isIntegrationsValid &&
            isCreativesValid &&
            isTargetingValid &&
            !hasPendingMedia &&
            !loading
          )
        case 4:
          return (
            isIntegrationsValid && isCreativesValid && isTargetingValid && hasWaveAccess && !loading
          )
        default:
          return false
      }
    },
    [
      hasWaveAccess,
      isIntegrationsValid,
      isCreativesValid,
      isTargetingValid,
      hasPendingMedia,
      loading,
    ],
  )

  useEffect(() => {
    if (eventId && !campaign) {
      fetchSetup()
    }
  }, [eventId, fetchSetup, campaign])

  useEffect(() => {
    dispatch({ type: actions.setCreativesValid, payload: isCreativesValid })
  }, [dispatch, isCreativesValid])

  useEffect(() => {
    dispatch({ type: actions.setTargetingValid, payload: isTargetingValid })
  }, [dispatch, isTargetingValid])

  useEffect(() => {
    dispatch({ type: actions.setIntegrationsValid, payload: isIntegrationsValid })
  }, [dispatch, isIntegrationsValid])

  return (
    <CampaignSetupStepProvider>
      <CampaignSetupContext.Provider value={state}>
        <AvailableTasteClustersContextProvider>
          <CampaignSetupDispatchContext.Provider
            value={{
              dispatch,
              fetchSetup,
              startSetup,
              cancelSetup,
              checkDisabledVariationsIntegrity,
              publishSetup,
              isStepValid,
              reconfigureSetup,
              overwriteTCCreatives,
              overwriteTCTargeting,
              updateDisabledAdVariations,
              editPublishedSetup,
              setHasPendingMedia,
            }}
          >
            <TargetingContextProvider>{children}</TargetingContextProvider>
          </CampaignSetupDispatchContext.Provider>
        </AvailableTasteClustersContextProvider>
      </CampaignSetupContext.Provider>
    </CampaignSetupStepProvider>
  )
}

CampaignSetupProvider.propTypes = {
  eventId: PropTypes.string.isRequired,
  campaign: PropTypes.shape({
    tc: PropTypes.string.isRequired,
    tc_run_id: PropTypes.number.isRequired,
    latest_campaign_setup: PropTypes.object,
  }),
  campaigns: PropTypes.array,
  children: PropTypes.node.isRequired,
}

export const useCampaignSetup = () => {
  const context = React.useContext(CampaignSetupContext)

  if (context === undefined) {
    throw new Error('useCampaignSetup must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCampaignSetupDispatch = () => {
  const context = React.useContext(CampaignSetupDispatchContext)

  if (context === undefined) {
    throw new Error('useCampaignSetupDispatch must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCurrentStep = () => {
  const context = React.useContext(CurrentStepContext)

  if (context === undefined) {
    throw new Error('useCurrentStep must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCurrentStepDispatch = () => {
  const context = React.useContext(CurrentStepDispatchContext)

  if (context === undefined) {
    throw new Error('useCurrentStepDispatch must be used within a CampaignSetupProvider')
  }

  return context
}

const _initialState = {
  loading: false,
  hasPendingMedia: false,
  error: null,
  lastSyncedAt: null,
  eid: null,
  setup: null,
  isCreativesValid: false,
  isIntegrationsValid: false,
  isTargetingValid: false,
  disabledVariations: {},
}

export const actions = Object.freeze({
  loading: 'LOADING',
  error: 'ERROR',
  setupLoaded: 'SETUP_LOADED',
  setupSynced: 'SETUP_UPDATED',
  setConfig: 'SET_CONFIG',
  setConversion: 'SET_CONVERSION',
  setTCCreatives: 'SET_TC_CREATIVES',
  setCreatives: 'SET_CREATIVES',
  setTargeting: 'SET_TARGETING',
  setIntegrations: 'SET_INTEGRATIONS',
  setCreativesValid: 'SET_CREATIVES_VALID',
  setTargetingValid: 'SET_TARGETING_VALID',
  setIntegrationsValid: 'SET_INTEGRATIONS_VALID',
  setSetupDisabledVariations: 'SET_SETUP_DISABLED_VARIATIONS',

  fetchAsync: 'FETCH_ASYNC',
  syncAsync: 'SYNC_ASYNC',
  createAsync: 'CREATE_ASYNC',
  reconfigureAsync: 'RECONFIGURE_ASYNC',
  deleteAsync: 'DELETE_ASYNC',
  publicAsync: 'PUBLISH_ASYNC',
  editAsync: 'EDIT_ASYNC',
  updateTCTargetingAsync: 'UPDATE_TC_TARGETING_ASYNC',
})

function reducer(state, action) {
  switch (action.type) {
    case actions.loading:
      state.loading = true
      return
    case actions.error:
      state.loading = false
      state.error = action.payload
      return
    case actions.setupLoaded:
      state.loading = false
      state.setup = action.payload
      if (!action.payload) {
        state.lastSyncedAt = null
        state.isCreativesValid = null
        state.isTargetingValid = null
        state.isIntegrationsValid = null
        state.hasPendingMedia = null
        state.disabledVariations = {}
      }
      return
    case actions.setupSynced:
      state.loading = false
      state.lastSyncedAt = action.payload
      return
    case actions.setCreativesValid:
      state.isCreativesValid = action.payload
      return
    case actions.setTargetingValid:
      state.isTargetingValid = action.payload
      return
    case actions.setIntegrationsValid:
      state.isIntegrationsValid = action.payload
      return
    case actions.setConfig: {
      const {
        creatives = state.setup.creatives,
        goal = state.setup.goal,
        total_budget = state.setup.total_budget,
        start_date = state.setup.start_date,
        end_date = state.setup.end_date,
      } = action.payload
      state.setup.goal = goal
      state.setup.total_budget = total_budget
      state.setup.start_date = start_date
      state.setup.end_date = end_date
      state.setup.creatives = creatives
      return
    }
    case actions.setConversion:
      state.setup.conversion_id = action.payload
      return
    case actions.setTCCreatives: {
      const { tc, tc_run_id, hasPendingMedia = false, ...tcCreatives } = action.payload
      const { creatives } = state.setup
      const newCreatives = calculateSetupCreativesFromUpdatedTCCreatives(
        creatives,
        tc,
        tc_run_id,
        tcCreatives,
      )

      state.setup.creatives = newCreatives
      state.hasPendingMedia = hasPendingMedia
      return
    }
    case actions.setHasPendingMedia: {
      state.hasPendingMedia = action.payload
      return
    }
    case actions.setCreatives: {
      state.setup.creatives = action.payload
      return
    }
    case actions.setTargeting: {
      state.setup.targeting = action.payload
      return
    }
    case actions.setIntegrations:
      state.setup.integration_details = action.payload
      return
    case actions.setSetupDisabledVariations:
      state.disabledVariations = action.payload
      return
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.syncAsync]: syncSetupAsync,
  [actions.fetchAsync]: fetchSetupAsync,
  [actions.createAsync]: createSetupAsync,
  [actions.reconfigureAsync]: reconfigureSetupAsync,
  [actions.deleteAsync]: deleteSetupAsync,
  [actions.updateTCTargetingAsync]: updateTCTargetingAsync,
  [actions.publicAsync]: publishSetupAsync,
  [actions.editAsync]: editPublishedSetupAsync,
}

function calculateSetupCreativesFromUpdatedTCCreatives(creatives, tc, tc_run_id, tcCreatives) {
  const otherCreatives = creatives.filter((c) => c.tc !== tc).map((c) => ({ ...c }))

  const media = tcCreatives.media?.filter((x) => x.status === fileStatus.success).map(m => ({
    id: m.id,
    url: m.url,
    size: m.size,
    type: m.type,
    index: m.index,
    width: m.width,
    height: m.height,
    status: m.status,
    filename: m.filename,
  }))
  const newCreatives = [...otherCreatives, { ...tcCreatives, tc, tc_run_id, media }]

  if (tcCreatives.call_to_action) {
    // set call to action on all creatives without a call to action
    newCreatives.forEach((c) => {
      if (!c.call_to_action) {
        c.call_to_action = tcCreatives.call_to_action
      }
    })
  }

  if (tcCreatives.destination_url) {
    // set destination url on all creatives without a destination url
    newCreatives.forEach((c) => {
      if (!c.destination_url || c.destination_url.filter(Boolean).length === 0) {
        c.destination_url = [...tcCreatives.destination_url]
      }
    })
  }

  return newCreatives
}

function deleteSetupAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, cancel } = action.payload
    const {
      setup: { id },
    } = getState()
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.deleteAsync(eid, cancel)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: null })
      await userReportsApi.deleteUserReports({ setup_process_ids: [id] })
      dispatch({ type: actions.fetchAsync, payload: { eid } })

      return { success: true }
    } else {
      handlerError(dispatch, response)

      return { success: false }
    }
  }
}

function createSetupAsync({ dispatch }) {
  return async (action) => {
    const { eid, config } = action.payload
    const defaultLanguage = i18n.language

    const payload = {
      ...config,
      creatives: config.creatives.map((creative) => ({
        ...creative,
        language: creative.language || defaultLanguage,
      })),
    }

    dispatch({ type: actions.loading })

    const response = await setupProcessApi.createAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    } else {
      handlerError(dispatch, response)

      return {
        success: false,
        data: response.data,
      }
    }
  }
}

function fetchSetupAsync({ dispatch }) {
  return async (action) => {
    const { eid } = action.payload
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.fetchAsync(eid)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        setup: response.data,
      }
    } else if (response.status === 404) {
      dispatch({ type: actions.setupLoaded, payload: null })

      return {
        success: true,
        setup: null,
      }
    } else {
      handlerError(dispatch, response)

      return { success: false }
    }
  }
}

function syncSetupAsync({ dispatch, getState }) {
  return async (action) => {
    dispatch({ type: actions.loading })
    let { eid, setup } = getState()
    if (action?.payload) {
      setup = action.payload.setup
    }

    const payload = buildSetupPayload(setup)

    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupSynced, payload: Date.now() })

      return {
        success: true,
        data: response.data,
      }
    }

    handlerError(dispatch, response)

    return { success: false }
  }
}

function reconfigureSetupAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, config } = action.payload
    const { setup } = getState()
    const { creatives: currentCreatives } = setup
    const language = currentCreatives.length > 0 ? currentCreatives[0].language : i18n.language

    const newCreatives = config.creatives.map((c) => {
      const currentCreative = currentCreatives.find(
        (cc) => cc.tc === c.tc && cc.tc_run_id === c.tc_run_id,
      )
      if (currentCreative) {
        return { ...currentCreative, ...c }
      }

      // a new creative
      return { ...c, language }
    })

    dispatch({ type: actions.loading })

    const payload = buildSetupPayload(
      { ...setup, ...config, creatives: newCreatives },
      { setupOnly: true, setDefaultTargeting: true },
    )
    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    } else {
      handlerError(dispatch, response)

      return {
        success: false,
        data: response.data,
      }
    }
  }
}

function updateTCTargetingAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, setup } = getState()
    const { shouldSync, ...targetingPayload } = action.payload
    const newTargeting = buildTargetingPayload(setup, targetingPayload)
    dispatch({ type: actions.setTargeting, payload: newTargeting })

    if (!shouldSync) {
      return { success: true }
    }

    const payload = buildSetupPayload(
      { ...setup, targeting: newTargeting },
      { setupOnly: true, setDefaultTargeting: true },
    )

    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    }

    handlerError(dispatch, response)

    return {
      success: false,
      data: response.data,
    }
  }
}

function publishSetupAsync({ dispatch, getState }) {
  return async () => {
    const { eid } = getState()
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.publishAsync(eid)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return { success: true }
    }

    handlerError(dispatch, response)

    return { success: false }
  }
}

function editPublishedSetupAsync({ dispatch, getState }) {
  return async ({ payload: { tc, tc_run_id, disabledVariations } }) => {
    const { eid, setup } = getState()
    dispatch({ type: actions.loading })

    const creative = setup.creatives.find((c) => c.tc === tc && c.tc_run_id === tc_run_id)
    const creatives = {
      ...creative,
    }

    if (disabledVariations) {
      creatives.excluded_variations = disabledVariations[tc]
    }

    if (!creatives.language) {
      creatives.language = i18n.language
    }

    const targeting = prepareTargetingPayload(setup.targeting, [creatives])[0]
    const { error, status } = await campaignSetupApi.updateAsync(eid, {
      tc: tc,
      tc_run_id: tc_run_id,
      creatives,
      targeting,
      audience_id: creatives.audience_id,
    })

    if (!error) {
      dispatch({ type: actions.setupLoaded, payload: null })

      return { success: true }
    }

    handlerError(dispatch, { error, status })

    return { success: false }
  }
}

function isErrorCode(error) {
  return typeof error === 'string' && error.startsWith('error.')
}

function handlerError(dispatch, { error, status, message, data }) {
  Sentry.captureException(error)
  console.error('Failed to save setup', error, status, message, data)

  const errorMsg = error.isAxiosError ? error.response?.data?.message : error.message
  const payload = isErrorCode(errorMsg ?? message)
    ? errorMsg ?? message
    : 'common.errors.inServerResponse'

  dispatch({
    type: actions.error,
    payload,
  })
}

function hasCustomAudience(tc, creatives) {
  const tcCreative = creatives.find((x) => x.tc === tc)
  return !!tcCreative?.audience_id
}

/**
 * Builds the targeting payload based on a specific - and updated - tc targeting.
 * It initializes all tcs without targeting or with invalid targeting with the new valid targeting of the source tc.
 *
 * @param {Object} setup - The setup object.
 * @param {Object} tcTargeting - The source targeting object.
 */
function buildTargetingPayload(setup, tcTargeting) {
  const { tc, targetingMode } = tcTargeting
  const { targeting, creatives } = setup
  const tcs = setup.creatives.map((x) => ({ tc: x.tc, tc_run_id: x.tc_run_id }))
  const resultPayload = []
  const _hasCustomAudience = hasCustomAudience(tc, creatives)

  for (const tasteCluster of tcs) {
    if (tasteCluster.tc === tcTargeting.tc) {
      resultPayload.push(tcTargeting)
      continue
    }

    const { postalCodes: { countries } = {}, rangeLocations: { included, excluded } = {} } =
      tcTargeting
    const currentTcTargeting = targeting.find((y) => y.tc === tasteCluster.tc)
    if (
      !currentTcTargeting ||
      !currentTcTargeting.targetingMode ||
      !validateLocationTargeting(
        targeting,
        tasteCluster.tc,
        tasteCluster.tc_run_id,
        _hasCustomAudience,
      ).valid ||
      isLocationTargetingEmpty(currentTcTargeting)
    ) {
      const tcPayload = { tc: tasteCluster.tc, tc_run_id: tasteCluster.tc_run_id, targetingMode }
      if (targetingMode === TARGETING_MODES.postalCodes && countries) {
        tcPayload.postalCodes = { countries }
      } else if (targetingMode === TARGETING_MODES.rangeLocations && (included || excluded)) {
        tcPayload.rangeLocations = { included, excluded }
      }

      resultPayload.push(tcPayload)
    } else {
      resultPayload.push(currentTcTargeting)
    }
  }

  return resultPayload
}

export function getDefaultConversionId(campaigns, setup) {
  let latestSelectedCampaign = {}
  if ((campaigns?.items ?? []).length) {
    const runningCampaigns = campaigns.items.filter((campaign) => isCampaignRunning(campaign))
    const orderedCampaigns = orderBy(runningCampaigns, ['initial_set_up_date'], 'desc')
    latestSelectedCampaign = orderedCampaigns.length ? orderedCampaigns[0] : {}
  }

  const defaultConversionId =
    (latestSelectedCampaign?.latest_campaign_setup?.conversion_id
      ? latestSelectedCampaign?.latest_campaign_setup?.conversion_id
      : setup?.conversion_id) ?? ''

  return defaultConversionId
}

function getDisabledVariationHashes(setup) {
  if (!setup) {
    return {}
  }

  const { creatives } = setup
  const integrityHashes = creatives.reduce((acc, c) => {
    acc[c.tc] = []
    return acc
  }, {})

  const disabledVariations = creatives.reduce((acc, c) => {
    acc[c.tc] = c.excluded_variations
    return acc
  }, {})

  try {
    for (const tc of Object.keys(disabledVariations)) {
      const tcCreative = creatives.find((c) => c.tc === tc)
      for (const variation of disabledVariations[tc]) {
        const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation
          .split('')
          .map(Number)
        const headline = tcCreative.headline[headlineIndex - 1]
        const body = tcCreative.body[bodyIndex - 1]
        const media = tcCreative.media[mediaIndex - 1]
        const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

        const hash = hashObjectValues({
          headline,
          body,
          media: media.url,
          destinationUrl,
        })

        integrityHashes[tcCreative.tc].push({
          [variation]: hash,
        })
      }
    }
  } catch (error) {
    console.error('Failed to init disabled variations cache', error)
  }

  return integrityHashes
}

function _checkDisabledVariationsIntegrity(creatives, integrityHashes, currentDisabledVariations) {
  const disabledVariations = creatives.reduce((acc, creative) => {
    acc[creative.tc] = creative.excluded_variations
    return acc
  }, {})

  for (const tc of Object.keys(disabledVariations)) {
    const tcCreative = [creatives].flat().find((c) => c.tc === tc)
    for (const variation of disabledVariations[tc]) {
      if (currentDisabledVariations && !currentDisabledVariations[tc]?.includes(variation)) {
        // Disabled variation was removed
        continue
      }
      const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation
        .split('')
        .map(Number)
      const headline = tcCreative.headline[headlineIndex - 1]
      const body = tcCreative.body[bodyIndex - 1]
      const media = tcCreative.media[mediaIndex - 1]
      const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

      const hash = hashObjectValues({
        headline,
        body,
        media: media.url,
        destinationUrl,
      })

      const originalHash = integrityHashes[tc]?.find((x) => x[variation])

      // no original hash found means this disabled variation is new
      if (originalHash && hash !== originalHash[variation]) {
        return false
      }
    }
  }

  return true
}
