import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import CampaignForm from './CampaignForm'
import VariationIndicator from '../VariationIndicator'
import { isCampaignRunning } from '../../../../api/models'
import { useCampaignSetup } from '../Context/CampaignSetupContext'

function updateReducer(state, action) {
  return {
    ...state,
    totalHeadlines: action.payload.headline?.filter(Boolean).length ?? 0,
    totalBodies: action.payload.body?.filter(Boolean).length ?? 0,
    totalMediaFiles: action.payload.media?.filter(Boolean).length ?? 0,
  }
}

export function CampaignEditor({ eventId, eventDate, campaign, goal, onCancel, ...rest }) {
  const { setup } = useCampaignSetup()
  const creative = setup.creatives.find((x) => x.tc === campaign.tc) || { tc: campaign.tc }
  const initialCounts = {
    totalHeadlines: creative.headline?.filter(Boolean).length ?? 0,
    totalBodies: creative.body?.filter(Boolean).length ?? 0,
    totalMediaFiles: creative.media?.filter(Boolean).length ?? 0,
  }
  const [creativeCounts, dispatchCreativeCountsAction] = useReducer(updateReducer, initialCounts)

  useEffect(() => {
    // update counts
    dispatchCreativeCountsAction({ payload: creative })
  }, [creative, dispatchCreativeCountsAction])

  return (
    <>
      {!isCampaignRunning(campaign) && <VariationIndicator {...creativeCounts} />}
      <CampaignForm
        eventId={eventId}
        eventDate={eventDate}
        campaign={campaign}
        goal={goal}
        onCancel={onCancel}
        {...rest}
      />
    </>
  )
}

CampaignEditor.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  goal: PropTypes.string,
  onCancel: PropTypes.func,
}
