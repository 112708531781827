import React from 'react'
import PropTypes from 'prop-types'
import EventHeaderDefault from './EventHeaderDefault'
import EventHeaderPrisma from './EventHeaderPrisma'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

export default function EventHeader({ event, showActiveBadge = true, children = null, classes, renderMenu }) {
  const hasPrismaAccess = hasPrismaAccessTier()
  if (!event) return null

  return (
    <header className={`event-header p-0 ${classes}`}>
      {hasPrismaAccess ? (
        <EventHeaderPrisma event={event} showActiveBadge={showActiveBadge} renderMenu={renderMenu}>
          {children}
        </EventHeaderPrisma>
      ) : (
        <EventHeaderDefault event={event} showActiveBadge={showActiveBadge} renderMenu={renderMenu}>
          {children}
        </EventHeaderDefault>
      )}
    </header>
  )
}

EventHeader.propTypes = {
  event: PropTypes.object.isRequired,
  menuOptions: PropTypes.array,
  showActiveBadge: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.string,
  renderMenu: PropTypes.func,
}
