import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  AssetClass,
  campaignKeywords,
  generativeCreativeField,
  isCampaignOpen,
  isCampaignPublished,
  isCampaignRunning,
  validateCreative,
} from 'api/models'

import { allFilesUploaded } from '../Media/media'
import Field, { ArrayField } from './CampaignForm/Field'

import DropDown from 'components/Shared/DropDown'
import ErrorMessage from 'components/Shared/ErrorMessage'
import InfoBox from 'components/Shared/InfoBox'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import Textarea from 'components/Shared/Textarea'

import { getUserReports } from 'api/userReports'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import useHasPermission, { hasPrismaAccessTier, Permissions } from 'hooks/useHasPermission'
import { fetchCampaignCtasAsync } from 'api/campaigns'
import constants from '../../../../constants'
import {
  arrayReducer,
  initStateArray,
  initStateObject,
  objectReducer,
  OVERWRITE,
} from 'reducers/default'
import { snakeCaseToPascalCase } from 'utils/helpers'
import { useCampaignSetup, useCampaignSetupDispatch } from '../Context/CampaignSetupContext'
import { hasEnabledVariations } from '../Context/disabledVariations'
import MediaUpload from '../Media/MediaUpload'
import AutoFill from './AutoFill'
import AudienceField from './CampaignForm/AudienceField'
import { creativeActions, creativeFormReducer } from './CampaignForm/creativeFormReducer'
import { DescriptionField } from './CampaignForm/DescriptionField'
import { Keywords } from './CampaignForm/Keywords'
import Targeting from './CampaignForm/Targeting'
import { validateLocationTargeting } from './CampaignForm/Targeting/targeting'
import { getRemovedField, parseCreatives } from './CampaignForm/utils'
import CoCreate from './CoCreate'

export default function CampaignForm({
  eventId,
  eventDate,
  campaign,
  goal,
  onCancel,
  onUpdate,
}) {
  const { t, i18n } = useTranslation()

  const { setup, loading: setupLoading, hasPendingMedia, error: setupError } = useCampaignSetup()
  const { overwriteTCCreatives } = useCampaignSetupDispatch()

  const hasCustomAudience = useMemo(() => {
    const tcCreative = setup.creatives.find((x) => x.tc === campaign.tc)
    return !!tcCreative?.audience_id
  }, [setup.creatives, campaign.tc])

  const partnerDetails =
    localStorage.getItem('partnerDetails') && JSON.parse(localStorage.getItem('partnerDetails'))
  const isITCRecommendations = ['ITC', 'ITC_v2'].includes(
    partnerDetails && partnerDetails.campaignRecommendationsModel,
  )

  const errorRef = useRef(null)

  const [ctaOptions, dispatchCtaOptionsAction] = useReducer(arrayReducer, initStateArray)

  const [userReports, userReportsDispatch] = useReducer(objectReducer, initStateObject)
  const currentPublishedTargeting = useRef(setup.targeting) // FIXME:
  const hasPrismaAccess = hasPrismaAccessTier()
  const hasWaveAccess = useHasPermission(Permissions.wave)

  const originalCreative = useMemo(() => {
    return parseCreatives(setup, campaign.tc, eventDate, hasPrismaAccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup, campaign.tc, eventDate])

  const [form, dispatchFormAction] = useReducer(creativeFormReducer, {
    ...initStateObject,
    content: originalCreative,
  })

  const sortedCtaOptions = [...ctaOptions.items]
    .map((option) => ({
      value: option,
      text: t([`Recommendations.campaign.setup.call_to_action.${option}`, snakeCaseToPascalCase(option)]),
    }))
    .sort((a, b) => {
      const collator = new Intl.Collator(i18n.language, { sensitivity: 'base' })
      return collator.compare(a.text, b.text)
    })

  useEffect(() => {
    getUserReports(userReportsDispatch, eventId, {
      reportType: 'WRONG_TOPIC_RECOMMENDATION',
      tc: campaign.tc,
      tc_run_id: campaign.tc_run_id,
    })
  }, [eventId, campaign])

  const reportedKeywords = useMemo(() => {
    if (userReports.content?.items) {
      return userReports.content?.items.map((item) => item.keyword)
    }
  }, [userReports])

  useEffect(() => {
    const controller = new AbortController()
    const hasPixel = setup.integration_details.some((x) => x.asset_class === AssetClass.pixel)
    fetchCampaignCtasAsync(dispatchCtaOptionsAction, setup.goal ?? goal, hasPixel, controller)

    return () => controller.abort()
  }, [setup.goal, goal, setup.integration_details])

  useEffect(() => {
    // This is needed as defaults are assigned to some fields (cta, destination_url) by the context.
    dispatchFormAction({ type: OVERWRITE, payload: originalCreative })
  }, [originalCreative])

  const keywords = useMemo(() => campaignKeywords(campaign), [campaign])

  useEffect(() => {
    if ((form.error || setupError) && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [form.error, errorRef, setupError])

  // when the component mounts this sets mobile nav to display none
  // the setup started buttons were not showing above the navbar on some browser/ devices
  useEffect(() => {
    const mobileNav = document.getElementById('mobile-navbar')
    if (mobileNav?.style) {
      mobileNav.style.display = 'none'
    }

    return () => {
      const mobileNav = document.getElementById('mobile-navbar')
      if (mobileNav?.style) {
        mobileNav.style.display = 'block'
      }
    }
  }, [])

  const isRunning = isCampaignRunning(campaign, { validateNotEnded: true })
  const isPublished = isCampaignPublished(campaign)
  const isOpen = isCampaignOpen(campaign)

  const handleChange = useCallback(
    (action, form, hasPendingMedia = false) => {
      dispatchFormAction(action)
      const newFields = creativeFormReducer(form, action).content
      const removedField = getRemovedField(form.content, newFields, action)

      overwriteTCCreatives({
        tc: campaign.tc,
        tc_run_id: campaign.tc_run_id,
        creatives: newFields,
        hasPendingMedia,
        creativeAction: {
          action,
          removedFields: [removedField],
        },
      })
    },
    [campaign.tc, campaign.tc_run_id, overwriteTCCreatives],
  )

  return (
    <>
      <div ref={errorRef} className="py-0" data-testid="campaign-form-error-banner">
        {(form.error || setupError) && (
          <ErrorMessage danger>{t(form.message || setupError)}</ErrorMessage>
        )}
      </div>
      <form className="campaign-form width-100" data-testid="campaign-form-test-id">
        <div className="d-flex flex-column justify-content-center">
          <Keywords
            eventId={eventId}
            setupProcessId={setup.id}
            campaign={campaign}
            isCampaignRunning={isRunning}
            reportedKeywords={reportedKeywords}
            keywords={keywords.headline}
            className="mb-0"
          />
          {(isOpen || isRunning) && (
            <CoCreate
              eventId={eventId}
              tc={form.content.tc}
              tc_run_id={form.content.tc_run_id}
              fields={{ bodies: form.content.body, headlines: form.content.headline }}
              promptForConfirmation={form.content.body.filter(Boolean).length > 0}
              onChange={(generatedContent) => {
                handleChange(
                  { type: creativeActions.bodyHeadlineUpdate, payload: generatedContent },
                  form,
                  setup.creatives,
                  isRunning,
                )
              }}
              language={form.content.language}
              onLanguageChanged={(lang) => {
                handleChange(
                  { type: creativeActions.cocreateLanguageUpdate, payload: lang },
                  form,
                  setup.creatives,
                  isRunning,
                )
              }}
              className={`${isITCRecommendations && isOpen ? 'with-border' : ''}`}
            />
          )}
        </div>

        <ArrayField
          as={Textarea}
          fieldName="body"
          form={form.content}
          onChange={(payload, index) =>
            handleChange(
              { type: creativeActions.bodyUpdate, index, payload },
              form,
              setup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isPublished}
          inputChildren={(index) =>
            index === 0 && (isOpen || isRunning) ? (
              <AutoFill
                eventId={eventId}
                tc={form.content.tc}
                tc_run_id={form.content.tc_run_id}
                language={form.content.language}
                creativeFieldName={generativeCreativeField.bodies}
                fields={form.content.body}
                promptForConfirmation={form.content.body.filter(Boolean).length > 0}
                onChange={(generatedContent) => {
                  handleChange(
                    { type: creativeActions.bodyUpdate, payload: generatedContent },
                    form,
                    setup.creatives,
                    isRunning,
                  )
                }}
              />
            ) : null
          }
          restFn={(index) => ({
            wrapperClass:
              index === 0 && (isOpen || isRunning) ? 'generative-input align-bottom' : '',
            inputClass: index === 0 ? 'textarea-autofill' : '',
          })}
          maxLength={280}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 200
              ? 'green'
              : length > 200 && length <= 250
                ? 'orange'
                : length > 250
                  ? 'red'
                  : ''
          }
          tooltip={{
            title: t('Recommendations.campaign.setup.body.title'),
            body: (
              <div style={{ fontSize: '.875rem', fontWeight: 500 }}>
                <p className="pb-2">{t('Tips.Recommendations.CreativeComposer.Body.1')}</p>
                <p>{t('Tips.Recommendations.CreativeComposer.Body.2')}</p>
              </div>
            ),
          }}
        >
          {isITCRecommendations ? null : (
            <Keywords
              eventId={eventId}
              setupProcessId={setup?.id}
              campaign={campaign}
              reportedKeywords={reportedKeywords}
              keywords={keywords.body}
            />
          )}
        </ArrayField>

        <ArrayField
          fieldName="headline"
          form={form.content}
          onChange={(payload, index) =>
            handleChange(
              { type: creativeActions.headlineUpdate, index, payload },
              form,
              setup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isPublished}
          inputChildren={(index) =>
            index === 0 && (isOpen || isRunning) ? (
              <AutoFill
                eventId={eventId}
                tc={form.content.tc}
                tc_run_id={form.content.tc_run_id}
                language={form.content.language}
                creativeFieldName={generativeCreativeField.headlines}
                fields={form.content.headline}
                promptForConfirmation={form.content.headline.filter(Boolean).length > 0}
                onChange={(generatedContent) => {
                  handleChange(
                    { type: creativeActions.headlineUpdate, payload: generatedContent },
                    form,
                    setup.creatives,
                    isRunning,
                  )
                }}
              />
            ) : null
          }
          restFn={(index) => ({
            wrapperClass: index === 0 && (isOpen || isRunning) ? 'generative-input' : '',
            inputClass: index === 0 ? 'input-autofill' : '',
          })}
          maxLength={80}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 40
              ? 'green'
              : length > 40 && length <= 60
                ? 'orange'
                : length > 60
                  ? 'red'
                  : ''
          }
          tooltip={{
            title: t('Recommendations.campaign.setup.headline.title'),
            body: (
              <div style={{ fontSize: '.875rem', fontWeight: 500 }}>
                <p className="pb-1">{t('Tips.Recommendations.CreativeComposer.Headline.1')}</p>
                <p>{t('Tips.Recommendations.CreativeComposer.Headline.2')}</p>
              </div>
            ),
          }}
        >
          {isITCRecommendations ? null : (
            <Keywords
              eventId={eventId}
              setupProcessId={setup.id}
              campaign={campaign}
              reportedKeywords={reportedKeywords}
              keywords={keywords.headline}
              className="first"
            />
          )}
        </ArrayField>

        <div className="mb-5 creative-composer-section-wrapper creative-composer-section-wrapper__media">
          <InfoBox title="Media" body={t('Recommendations.campaign.setup.media.info')}>
            <label className="fs-18 d-inline-block">Media</label>
          </InfoBox>
          {!isITCRecommendations && (
            <Keywords
              eventId={eventId}
              setupProcessId={setup.id}
              campaign={campaign}
              isCampaignRunning={isRunning}
              reportedKeywords={reportedKeywords}
              keywords={keywords.media}
              className="last"
            />
          )}
          <MediaUpload
            initialMedia={form.content.media}
            onChange={(files, removedIndex = null, hasPendingMedia = false) => {
              handleChange(
                { type: creativeActions.mediaUpdate, payload: files, index: removedIndex },
                form,
                hasPendingMedia,
              )
            }}
            maxItems={10}
            canAdd={!isPublished}
            canRemove={!isPublished && (!isRunning || form.content.media.length > 1)}
            canReplace={isPublished || (isRunning && form.content.media.length === 1)}
          />
        </div>

        <ArrayField
          fieldName="destination_url"
          form={form.content}
          minCount={1}
          onChange={(payload, index) =>
            handleChange(
              { type: creativeActions.urlUpdate, index, payload },
              form,
              setup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isRunning}
          isRemovingEnabled={!isRunning}
          removeBtnClassNames="input__remove--r"
          maxLength={250}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 200
              ? 'green'
              : length > 200 && length <= 240
                ? 'orange'
                : length > 240
                  ? 'red'
                  : ''
          }
          validator={(val) => {
            return {
              isValid: val && constants.URL_VALIDATION_REGEX.test(val),
              errorMsg: t('common.errors.wrongUrlFormat'),
            }
          }}
          showPositiveFeedback={!isRunning && !isPublished}
          placeholder="https://www.your-website.com"
          validateOnBlur
          tooltip={{
            title: t('Tips.Recommendations.CreativeComposer.DestinationUrl.title'),
            body: (
              <ol
                style={{
                  listStyle: 'decimal',
                  paddingLeft: '1.4rem',
                  fontSize: '.875rem',
                  fontWeight: 500,
                }}
              >
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.1')}</li>
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.2')}</li>
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.3')}</li>
              </ol>
            ),
          }}
          wrapperClass="input-cell--input-padding-max"
        />

        <div className="creative-composer-section-wrapper mb-5">
          <Field
            fieldName="call_to_action"
            as={DropDown}
            tooltip={{ content: 'Tips.Recommendations.CreativeComposer.CallToAction' }}
            value={form.content.call_to_action ?? ''}
            options={sortedCtaOptions.map((x) => ({ value: x.value, text: x.text }))}
            onChange={(payload) =>
              handleChange(
                { type: creativeActions.callToActionUpdate, payload },
                form,
                setup.creatives,
                isRunning,
              )
            }
            required={false} // For the dropdown. Otherwise a '*' is added when value is empty.
            classNames="cta-dropdown"
            disabled={isPublished}
            renderItem={(item) => {
              const option =
                sortedCtaOptions.find((x) => x.value === item?.value) ?? sortedCtaOptions[0]
              return option
                ? t([
                  `Recommendations.campaign.setup.call_to_action.${option.text}`,
                  snakeCaseToPascalCase(option.text),
                ])
                : '_'
            }}
          />
        </div>

        <div className="creative-composer-section-wrapper mb-5">
          <AudienceField
            audienceId={form.content?.audience_id}
            onChange={(payload, index) =>
              handleChange(
                { type: creativeActions.audienceUpdate, index, payload: payload },
                form,
                setup.creatives,
                isRunning,
              )
            }
            campaign={campaign}
          />
        </div>

        <div className="creative-composer-section-wrapper mb-5">
          <Targeting
            tc={campaign.tc}
            tc_run_id={campaign.tc_run_id}
            onChange={(targeting) => {
              // For editing a published campaign
              currentPublishedTargeting.current = targeting
            }}
            // Note: this only disables the dropdown.
            // If postal-codes, or other editable items are selected,
            // the user can still edit them.
            disabled={isPublished}
            showValidationErrors={!isOpen}
          />
        </div>

        <div>
          <DescriptionField
            form={form.content}
            isCampaignPublished={isPublished}
            onChange={(payload) =>
              handleChange(
                { type: creativeActions.descriptionUpdate, payload },
                form,
                setup.creatives,
                isRunning,
              )
            }
          />
        </div>

        <div className="my-3">
          {form.loading || setupLoading ? (
            <LoadingSpinner data-testid="loading-spinner" title={t('common.sending')} />
          ) : (
            <div className="creative-composer-controls-container">
              {isRunning ? (
                <>
                  <TransparentBgButton
                    text={t('common.cancel')}
                    onClick={() => {
                      dispatchFormAction({
                        type: OVERWRITE,
                        payload: { ...initStateObject, content: originalCreative },
                      })
                      if (onCancel) onCancel()
                    }}
                    color="navy"
                  />
                  <SecondaryButton
                    data-testid="save-campaign-form-btn"
                    text={t('common.update')}
                    onClick={(e) => {
                      e.preventDefault()
                      onUpdate()
                    }}
                    color="navy"
                    disabled={
                      form.loading ||
                      setupLoading ||
                      hasPendingMedia ||
                      !hasWaveAccess ||
                      !allFilesUploaded(form.content.media) ||
                      !validateCreative(form.content, { setupOnly: false }).valid ||
                      !hasEnabledVariations(setup.creatives) ||
                      !validateLocationTargeting(
                        currentPublishedTargeting.current,
                        campaign.tc,
                        campaign.tc_run_id,
                        hasCustomAudience,
                      ).valid
                    }
                  />
                </>
              ) : null}
            </div>
          )}
        </div>
      </form>
    </>
  )
}

CampaignForm.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  goal: PropTypes.string,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
}
